<template>
  <div id="diagram-tree" class="part-component part-diagram">
    <div class="tree-scroll">
      <div class="tree-bird absolute">
        <img
          class="parallax-item parallax-relative"
          :src="decal"
          :data-depth="5"
        />
      </div>
      <div class="diagram-scroll-indicator">
        <div class="swipe-icon"></div>
      </div>
      <div class="tree-diagram">
        <div class="tree-part tree-ink tree-ink-green absolute">
          <img
            :src="images.treeInkGreen"
            class="parallax-item parallax-relative"
            data-depth="4.5"
          />
        </div>
        <div class="tree-part tree-ink tree-ink-yellow absolute">
          <img
            :src="images.treeInkYellow"
            class="parallax-item parallax-relative"
            data-depth="1"
          />
        </div>
        <div class="tree-part tree-ink tree-ink-red absolute">
          <img
            :src="images.treeInkRed"
            class="parallax-item parallax-relative"
            data-depth="4.5"
          />
        </div>

        <div class="tree-bottom tree-part">
          <img
            :src="images.treeBottom"
            class="parallax-item parallax-relative"
            data-depth="1.6"
          />
        </div>
        <div class="tree-top tree-part absolute">
          <img
            :src="images.treeTop"
            class="parallax-item parallax-relative"
            data-depth="2.2"
          />
        </div>

        <div class="tree-text tree-control absolute principle-1">
          <h3 class="parallax-item" data-depth="2.8">Founding Principle 1</h3>
        </div>
        <div class="tree-text tree-control absolute">
          <h3 class="parallax-item" data-depth="2.8">Founding Principle 2</h3>
        </div>
        <div class="tree-text absolute tree-control principle-3">
          <h3 class="parallax-item" data-depth="2.8">Founding Principle 3</h3>
        </div>

        <div
          class="diagram-button tree-control absolute parallax-item"
          data-depth="2.8"
        >
          <button
            class="btn btn-round btn-info-box"
            @click="$emit('open-modal')"
          >
            <i class="ai-feather"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramTree",
  components: {},
  mixins: [partComponent],
  props: ["show", "images", "decal"],
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.set(
        ".tree-diagram",
        {
          autoAlpha: 1,
        },
        0
      )
        .add("treeTopIn", 0.6)
        .from(
          ".tree-top",
          1.3,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(0.4),
            scale: 0.2,
            rotation: 30,
          },
          0.1
        )
        .from(
          ".tree-ink-green",
          1.3,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(1),
            scale: 0,
            rotation: -10,
          },
          "treeTopIn"
        )
        .from(
          ".tree-ink-yellow",
          1.3,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(1),
            scale: 0,
            rotation: 10,
          },
          "treeTopIn+=0.3"
        )
        .from(
          ".tree-ink-red",
          1.3,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(1),
            scale: 0,
          },
          "treeTopIn+=0.9"
        )
        .from(
          ".tree-bottom",
          1,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(1),
          },
          "treeTopIn+=1"
        )
        .from(
          ".tree-control",
          0.9,
          {
            autoAlpha: 0,
            ease: Back.easeInOut.config(1),
            scale: 0,
            rotation: 10,
          },
          "treeTopIn+=0.9"
        )
        .from(
          ".tree-bird",
          0.7,
          {
            ease: Back.easeInOut.config(0.5),
            autoAlpha: 0,
            scale: "0.8",
            x: "+=30%",
            y: "+=10%",
          },
          "treeTopIn+=1.3"
        );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.tree-diagram {
  position: relative;
  max-width: none;
  max-width: 1260px;
  width: 100%;
  opacity: 0;
  min-width: 660px;
}
.tree-scroll {
  overflow: hidden;
  position: relative;
}
.diagram-button {
  top: 31.6% !important;
  left: 20.6% !important;
  z-index: 4;
}
.tree-part {
  width: 100%;
}
.tree-part img {
  width: auto;
}
.tree-top {
  left: 6%;
  top: 2.51%;
  z-index: 1;
}
.tree-top img {
  max-width: 78%;
}
.tree-bottom {
  z-index: 1;
  position: relative;
}
.tree-bottom img {
  width: 100%;
}
.tree-ink {
  z-index: 2;
  opacity: 0.85;
}
.tree-ink-green {
  top: -7.9%;
  left: -0.1%;
  mix-blend-mode: multiply;
}
.tree-ink-green img {
  max-width: 96%;
}
.tree-ink-yellow {
  top: -12%;
  left: 4.4%;
  z-index: 3;
  mix-blend-mode: darken;
  opacity: 0.88;
}
.tree-ink-yellow img {
  max-width: 95.5%;
}
.tree-ink-red {
  bottom: 8.5%;
  top: auto;
  left: 26%;
  z-index: 1;
  opacity: 0.5;
  mix-blend-mode: darken;
}
.tree-ink-red img {
  max-width: 46%;
}
.tree-text {
  top: 22.1%;
  left: 16.5%;
  z-index: 4;
}
.tree-text h3 {
  font-weight: normal;
  font-size: 2.1vw;
  margin: 0;
  color: #5e5e5e;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
  white-space: nowrap;
}
.tree-text.principle-1 {
  top: 8.5%;
  left: 39.3%;
}
.tree-text.principle-3 {
  top: 22.8%;
  left: 63%;
}
.tree-bird {
  width: 110px;
  right: 40px;
  top: 20px;
  display: none;
}
.tree-bird img {
  right: 0;
  max-width: 100%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (min-width: 1255px) {
  .tree-text h3 {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .tree-text h3 {
    font-size: 14px;
  }
  .diagram-button button {
    font-size: 12px;
  }
}

@media screen and (max-width: 630px) {
  .tree-scroll {
    margin-left: -10px;
    max-width: 640px;
  }
  .diagram-scroll-indicator {
    display: block;
    margin-left: 10px;
    margin-top: 10px;
  }
  .tree-bird {
    display: block;
  }
  .diagram-button button {
    font-size: 10.5px;
  }
}
</style>
