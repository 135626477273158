<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column">
        <div class="layout-row row-1 layout-align-center-center">
          <div
            class="
              layout-column
              col col-1 col-padding
              layout-align-center-end
              flex
            "
          >
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative"
              :show="showTitle"
            ></section-title>
          </div>
          <div
            class="
              layout-column
              col col-padding col-2
              layout-align-center-start
              flex
            "
          >
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="2"
              :timescale="timescaleLead"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="layout-row row-2 layout-align-center-start">
          <diagram-tree
            :show="showDiagram"
            :images="data.images"
            :decal="data.images.kingfisher"
            v-on:open-modal="showModal(data.modals_standalone[0])"
          ></diagram-tree>

          <div class="container fixed container-decal">
            <decal
              class="center-right fixed"
              :show="showDecal"
              :image="data.images.kingfisher"
              :parallaxDepth="5"
              :customTimeline="timelineDecal"
            ></decal>
          </div>
        </div>
      </div>
    </div>
    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart, modalFunctions } from "@/mixins/core.js";

import DiagramTree from "@/views/chapters/2/DiagramTree.vue";
import ModalMain from "@/modals/ModalMain.vue";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Decal from "@/components/ui/Decal.vue";

export default {
  name: "Chapter-2-2",
  mixins: [chapterPart, modalFunctions],
  components: {
    DiagramTree,
    ModalMain,
    SectionTitle,
    Lead,
    Decal,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 100],
        ["showTitle", 0],
        ["showDiagram", 1800],
        ["showLead", 0],
        ["showDecal", 650],
      ],
      timelineDecal: {
        duration: 0.7,
        animations: {
          ease: Back.easeInOut.config(0.5),
          autoAlpha: 0,
          scale: "0.8",
          x: "+=30%",
          y: "+=10%",
        },
      },
      timescaleLead: 1.1,
    };
  },
};
</script>

<style scoped>
.view-part {
  /* background-image: url("https://media.londolozi.com/africa/diagrams/tree/part-2-2.jpg"); */
  background-position: -4px 0;
  background-repeat: no-repeat;
  background-size: 101%;
}
.row-1 {
  padding: 1rem 0 0;
  z-index: 1;
}
.row-2 {
  background: rgba(255, 255, 255, 0.4);
  z-index: 0;
}
.col-1 {
  max-width: 41.5%;
}
.col-2 {
  padding-left: 0;
}
.part-lead {
  max-width: 38em;
}
.container.fixed {
  max-width: 1660px;
  overflow: visible;
  z-index: 0;
}

.section-decal {
  visibility: inherit;
  opacity: 1;
  width: 23%;
  right: -10%;
  top: 38%;
  overflow: visible;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
  .row-1 {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .part-lead {
    max-width: 35em;
  }
}

@media screen and (max-width: 1024px) {
  .part-lead {
    max-width: 27em;
  }
  .section-title {
    margin-top: 0.27em;
  }
  .row-2 {
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .row-1 {
    flex-direction: column;
    padding-top: 0;
  }
  .row-1 > * {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .col-2 {
    padding: var(--col-padding);
  }
  .part-lead {
    max-width: 36em;
  }
  .section-title {
    margin-top: 0.378em;
  }
}

@media screen and (max-width: 639px) {
  .part-lead {
    max-width: 26em;
  }
  .row-2 {
    padding-top: 1.5rem;
  }
  .container-decal {
    display: none;
  }
}

@media screen and (max-width: 630px) {
  .row-2 {
    overflow: hidden;
    overflow-x: scroll;
    flex-direction: column;
  }
  .section-decal {
    left: 500px;
    right: auto;
  }
  .part-lead {
    margin-right: var(--col-padding-adjust);
  }
}
</style>
